























































































import Vue from "vue";
import {Component} from "vue-property-decorator";
import Github from '@/components/organization/logo/github.vue';

@Component({
    name: "faq",
    components: {Github},
    metaInfo: {
        title: "FAQ - Stellarbeat.io",
        meta: [
            {name: "Frequently asked questions", content: "Help faq"},
        ],
    },
})
export default class About extends Vue {
}
